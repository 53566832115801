import React from "react";
import { Image, Item } from "semantic-ui-react";
import { css, cx } from "@emotion/css";
import { siteTextColor } from '../styles';
import { mq } from '../styles';

export interface PressItem {
    image: string,
    headline: string,
    url: string,
    date: string
}

interface PressItemsProps {

}

interface PressItemsProps {
    items: PressItem[]
}

export class PressItems extends React.Component<PressItemsProps, PressItemsProps> {
    render() {
        return (
            <div className={pressItems}>
                <Item.Group>
                    {this.props.items.map(item => (
                        <Item key={item.url}>
                            <Item.Image size="tiny" className={pressImage} src={item.image} />

                            <Item.Content className={pressContent}>
                                <Item.Header className={pressHeadline}>{item.headline}</Item.Header>
                                <Item.Extra className={extra}>
                                    <a target="_blank" href={item.url} rel="noopener noreferrer">READ MORE</a>
                                </Item.Extra>
                            </Item.Content>
                        </Item>
                    ))}
                </Item.Group>
            </div >
        );
    };
}

const pressImage = css(mq({
    backgroundColor: "white!important",
    display: ['none!important', 'inline!important', 'inline!important'],
    width: "60px!important",
    height: "60px!important",
}));

const pressHeadline = cx(siteTextColor, css(mq({
    fontSize: ["12px!important", "14px!important", "14px!important"],
    fontWeight: "bold",
})));

const extra = cx(siteTextColor, css({
    fontSize: "10px!important",
}));

const pressContent = cx(siteTextColor, css(mq({
    paddingTop: ['0px!important', 'inherit', 'inherit'],

})));

const pressItems = css(mq({
    width: ["90%", "90%", "40%"],
    marginLeft: ["10px", "50px", "50px"],
    marginTop: ["10px", "50px", "50px"]
}));
