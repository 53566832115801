import React from "react";
import 'semantic-ui-css/semantic.min.css';
import Layout from "../components/layout";
import '../layout.css';
import { css, cx } from "@emotion/css"
import { Link } from "gatsby";
import { PressItems } from "../components/pressitem";
import { Item, Icon } from "semantic-ui-react";
import { siteSubText, siteHeaderText, siteBodyText } from '../styles';
import geekwireLogo from '../../assets/images/geekwire.png';
import wsjLogo from '../../assets/images/wsj.jpg';
import youtubeLogo from '../../assets/images/youtube.jpg';
import biLogo from '../../assets/images/bi.jpg';
import a16zLogo from '../../assets/images/a16z.png';
import techCrunchLogo from '../../assets/images/techcrunch.png';
import pandoLogo from '../../assets/images/pando.png';
import rsinghImage from '../../assets/images/rsingh.jpg';

interface AboutProps {
}

interface AboutState {

}

const PRESS_ITEMS = [
    {
        image: geekwireLogo,
        headline: "Heavily-funded real estate tech startup Compass hires former Distelli and Amazon exec Rahul Singh",
        url: "https://www.geekwire.com/2019/tech-moves-heavily-funded-real-estate-tech-startup-compass-hires-former-distelli-amazon-exec-rahul-singh/",
        date: "Feb 28, 2019"
    },
    {
        image: wsjLogo,
        headline: "Puppet Acquires Andreessen Horowitz-Backed Distelli",
        url: "https://www.wsj.com/articles/puppet-acquires-andreessen-horowitz-backed-distelli-1506038464",
        date: "Sep 21, 2017"
    },
    {

        image: youtubeLogo,
        headline: "Rahul Singh, VP of Engineering for Puppet Pipelines",
        url: "https://www.youtube.com/watch?v=MrrFSmmglw8",
        date: "May 03, 2018"
    },
    {
        image: biLogo,
        headline: "How The Fourth Engineer At Amazon Web Services Finally Found His Startup Passion",
        url: "https://www.businessinsider.com/distelli-andreessen-horowitz-funds-former-aws-engineers-startup-2015-1",
        date: "Jan 20, 2015"
    },
    {
        image: a16zLogo,
        url: "https://a16z.com/2015/01/20/the-rise-of-devops/",
        headline: "Distelli and the Rise of DevOps",
        date: "Jan 20, 2015"
    },
    {
        image: geekwireLogo,
        headline: "How Seattle’s Distelli is making one of the hottest products in enterprise tech easier to use",
        url: "https://www.geekwire.com/2017/seattles-distelli-making-one-hottest-products-enterprise-tech-easier-use/",
        date: "Jun 21, 2017"
    },
    {
        image: wsjLogo,
        headline: "Distelli Raises $2.8M to Make Software Easier to Deploy",
        url: "https://www.wsj.com/articles/DJFVW00120150120eb1kb1tyi",
        date: "Jan 20, 2015"
    },
    {
        image: techCrunchLogo,
        headline: "Distelli Snags $2.8M To Build Deployment As A Service",
        url: "https://techcrunch.com/2015/01/20/distelli-snags-2-8m-to-build-deployment-as-a-service/",
        date: "Jan 20, 2015"
    },
    {
        image: pandoLogo,
        headline: "Andreessen Horowitz-backed Distelli brings Amazon-caliber code deployment to all companies",
        url: "https://pando.com/2015/01/20/andreessen-horowitz-backed-distelli-brings-amazon-caliber-code-deployment-to-all-companies/",
        date: "Jan 20, 2015"
    },
    {
        image: geekwireLogo,
        headline: "Geek of the Week: Rahul Singh is a ‘dev at heart,’ aiming to transform the world of DevOps",
        url: "https://www.geekwire.com/2015/rahul-singh/",
        date: "Oct 28, 2015"
    },
    {
        image: geekwireLogo,
        headline: "Amazon Web Services vet scores $2.8M from Andreessen Horowitz and others for hot Seattle startup Distelli",
        url: "https://www.geekwire.com/2015/amazon-web-services-vet-scores-2-8m-andreessen-horowitz-others-hot-seattle-startup-distelli/",
        date: "Jan 20, 2015"
    },
    {
        image: geekwireLogo,
        url: "https://www.geekwire.com/2017/distelli-aims-make-cloud-software-development-easier-open-source-container-registry/",
        headline: "Distelli aims to improve multi-cloud software development with new open-source container registry",
        date: "Apr 03, 2017"
    },
]

class about extends React.Component<AboutProps, AboutState> {

    render() {
        return (
            <Layout>
                <Item.Group>
                    <Item>
                        <Item.Image size="medium" className={profileImage} src={rsinghImage} />
                        <Item.Content>
                            <Item.Header className={siteHeaderText}>Rahul Singh</Item.Header>
                            <Item.Extra className={subText}>
                                Engineer, Entrepreneur, Executive<br />
                                <i>
                                    <a target="_blank"
                                        href="https://www.geekwire.com/2019/tech-moves-heavily-funded-real-estate-tech-startup-compass-hires-former-distelli-amazon-exec-rahul-singh/">
                                        VPE @Compass
                                    </a>,
            <a target="_blank"
                                        href="https://www.geekwire.com/2017/puppet-acquires-distelli-bolster-cloud-computing-automation-platform/">
                                        ex-founder
            </a>,
            <a target="_blank"
                                        href="https://www.geekwire.com/2015/amazon-web-services-vet-scores-2-8m-andreessen-horowitz-others-hot-seattle-startup-distelli/">
                                        ex-aws
            </a>
                                </i>
                            </Item.Extra>
                            <Item.Description className={siteBodyText}>
                                After a recent introduction to Rust, I noticed that the standard library didn't offer a Bitvector. So I asked myself, what would it take to write the fastest possible Bitvector?. As I waded deeper into unsafe rust, I challenged myself to build a LinkedList faster than <a target="_blank" href="https://doc.rust-lang.org/std/collections/struct.LinkedList.html" rel="noopener noreferrer">std::LinkedList</a> with <i>O(1)</i> time inserts in the middle. With that small start, it was the logical next step to open source the code and offer production ready data structures and algorithms. Over time I plan on expanding the collection with help from the community.
                            </Item.Description>
                            <Item.Description className={siteBodyText}>I enjoy writing code (in <a href="https://github.com/arrsingh/dotemacs/blob/master/.emacs" rel="noopener noreferrer">emacs</a>) and this is my contribution to Rust.</Item.Description>
                            <Item.Extra>
                                <a target="_blank" href="https://www.linkedin.com/in/rahulsingh5/" rel="noopener noreferrer"><Icon name="linkedin" size="big" /></a>
                                <a target="_blank" href="https://github.com/arrsingh" rel="noopener noreferrer"><Icon name="github" size="big" /></a>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <PressItems items={PRESS_ITEMS} />
            </Layout >
        );
    }
}

export default about;

const profileImage = css({
    width: '100px'
});

const subText = cx(siteSubText, css({
    marginTop: "0px!important"
}));
